export const CURATOR_API_URL = 'https://api.curator.io/v1';

// COOKIES
export const COOKIE_VERSIONS = {
  fr: 'bikube FR',
  en: 'bikube EN',
};

// BOOKINGS
export const LINK_TO_USER_BOOKING_AREA = 'https://bikube.my.site.com/clientportal/login';

// LOCALE
export const LOCALE_FR = 'fr';
export const LOCALE_EN = 'en';
export const DEFAULT_LOCALE = LOCALE_FR;

// PHONE NUMBER
export const PHONE_NUMBER = {
  [LOCALE_EN]: '+33 1 71 25 08 20',
  [LOCALE_FR]: '01 71 25 08 20',
};

export const BOOKING_PHONE_NUMBER = {
  [LOCALE_EN]: '+33 1 89 71 80 03',
  [LOCALE_FR]: '01 89 71 80 03',
};

// CONTACT LINKS
export const CONTACT_FORMS_LINKS = {
  cgu: { [LOCALE_FR]: '/conditions-generales-de-vente', [LOCALE_EN]: '/terms-of-sales' },
  poc: { [LOCALE_FR]: '/politique-de-confidentialite', [LOCALE_EN]: '/private-policy' },
  collect: {
    [LOCALE_FR]: '/legal',
    [LOCALE_EN]: '/en/legal',
  },
  invest: {
    [LOCALE_FR]: 'https://www.vinci-immobilier.com/charte-de-confidentialite',
    [LOCALE_EN]: 'https://www.vinci-immobilier.com/charte-de-confidentialite',
  },
  partner: {
    [LOCALE_FR]: 'https://www.vinci-immobilier.com/partenaires-vinci',
    [LOCALE_EN]: 'https://www.vinci-immobilier.com/partenaires-vinci',
  },
};

export const LINK_USER_SPACE = 'https://bikube.my.site.com/clientportal/login';

export const FAQ_LINK = {
  [LOCALE_FR]: 'https://bikube.my.site.com/aide',
  [LOCALE_EN]: 'https://bikube.my.site.com/aide/s/?language=en_US',
};

// MAPS
export const DEFAULT_MAP_FRANCE_LOCATIONS = {
  lat: 46.864716,
  lng: 2.349014,
};

export const MAP_LIBRARIES = ['places'];

export const MAP_TRAVEL_MODES = {
  WALKING: 'WALKING',
  TRANSIT: 'TRANSIT',
  DRIVING: 'DRIVING',
  BICYCLING: 'BICYCLING',
};

export const MAP_TRAVEL_MODES_ICON = {
  WALKING: 'directionsWalk',
  TRANSIT: 'directionsSubway',
  DRIVING: 'directionsCar',
  BICYCLING: 'directionsBike',
};

// RESIDENCES STAY TYPES
export const MEDIUM_LONG_STAY = 'Moyen/Long Séjour';
export const SHORT_STAY = 'Court séjour';
export const CO_LIVING = 'Co-living';
export const CO_WORKING = 'Co-working';
export const RESTORATION = 'Restauration';

// SLUGS
export const RESIDENCE_SLUG = 'residence';
export const CONTACT_SLUG = 'contact';
export const BLOG_SLUG = 'actus-events';
export const CONCEPT_SLUG = 'concept';
export const HOTELS_SLUG = 'hotel';
export const STAY_SLUG = 'sejourner';

export const SLUG_FR_COWORKINGS = 'coworking';
export const SLUG_EN_COWORKINGS = 'coworking';

export const SLUG_EN_COLIVING_RESIDENCE = 'coliving-residence';
export const SLUG_FR_COLIVING_RESIDENCE = 'residence-coliving';

export const SLUG_FR_COWORKING = 'coworking';
export const SLUG_EN_COWORKING = 'coworking';

export const SLUG_FR_RESTORATION = 'restaurant';
export const SLUG_EN_RESTORATION = 'restaurant';

export const SLUG_FR_HOTEL = 'hotel';
export const SLUG_EN_HOTEL = 'hotel';

export const SLUG_FR_SEARCH = 'residences-coliving';
export const SLUG_EN_SEARCH = 'coliving-residences';

export const VALID_PREFIXS_SLUGS = [];

export const URL_SLUGS_RESIDENCES = {
  [LOCALE_EN]: SLUG_EN_COLIVING_RESIDENCE,
  [LOCALE_FR]: SLUG_FR_COLIVING_RESIDENCE,
};

export const URL_SLUGS_ACTIVITIES = {
  [LOCALE_FR]: {
    [MEDIUM_LONG_STAY]: SLUG_FR_COLIVING_RESIDENCE,
    [SHORT_STAY]: SLUG_FR_HOTEL,
    [CO_LIVING]: SLUG_FR_COLIVING_RESIDENCE,
    [CO_WORKING]: SLUG_FR_COWORKING,
    [RESTORATION]: SLUG_FR_RESTORATION,
  },
  [LOCALE_EN]: {
    [MEDIUM_LONG_STAY]: SLUG_EN_COLIVING_RESIDENCE,
    [SHORT_STAY]: SLUG_EN_HOTEL,
    [CO_LIVING]: SLUG_EN_COLIVING_RESIDENCE,
    [CO_WORKING]: SLUG_EN_COWORKING,
    [RESTORATION]: SLUG_EN_RESTORATION,
  },
};

export const URL_SEARCH_PAGES = {
  [LOCALE_FR]: SLUG_FR_SEARCH,
  [LOCALE_EN]: SLUG_EN_SEARCH,
};
export const URL_COLIVING_RESIDENCE_PAGES = {
  [LOCALE_FR]: SLUG_FR_COLIVING_RESIDENCE,
  [LOCALE_EN]: SLUG_EN_COLIVING_RESIDENCE,
};
export const URL_COWORKINGS_PAGES = {
  [LOCALE_FR]: SLUG_FR_COWORKINGS,
  [LOCALE_EN]: SLUG_EN_COWORKINGS,
};

export const BANNED_PAGE_SLUGS = [
  BLOG_SLUG,
];
